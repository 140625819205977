import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;


// 角色管理 列表
export function PostSystemPowerRoles(data) {
  return request({
    url: '/backend/web/system-power/roles',
    method: GET,
    params:data
  });
}

// 角色状态改变
export function PostsyStemPowerRoleStatus(data) {
  return request({
    url: '/backend/web/system-power/role-status',
    method: POST,
    data
  });
}

// 角色新增时的详情
export function GETSystemPowerAlMenu(data) {
  return request({
    url: '/backend/web/system-power/all-menu',
    method: GET,
    params:data
  });
}

// 角色详情
export function GETSystemPowerRoleDetail(data) {
  return request({
    url: '/backend/web/system-power/role-detail',
    method: POST,
    data
  });
}

// 角色删除
export function SystemPowerRoleDelete(data) {
  return request({
    url: '/backend/web/system-power/role-delete',
    method: POST,
    data
  });
}

// 角色新增提交
export function PostSystemPowerRolesCreate(data) {
  return request({
    url: '/backend/web/system-power/roles-create',
    method: POST,
    data
  });
}

// 角色编辑提交
export function PostSystemPowerRolesUpdate(data) {
  return request({
    url: '/backend/web/system-power/role-update',
    method: POST,
    data
  });
}
